.container {
    display: flex;
    flex-direction: column;
    color: #2b2c2d;
    padding: 0px 15px 50px;
  }
  
  .title {
    width: 100%;
    font: 700 28px/74% Georama, -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .detailsContainer {
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    padding: 30px;
  }

  .titleContainer{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  /* .detailsSelect{
    width: 300px;
    font-size: 18px;
  } */

  .showSection{
    display: flex;
    align-items: center;
  }

  .showSection p{
    width: 180px;
    font-size: 18px;
    margin-bottom: 0;
    color: black;
  }

  @media(max-width: 1600px){
    .title{
        font-size: 24px;
    }

    .detailsContainer{
        font-size: 18px;
    }
  }

  @media(max-width: 1250px){
    .title{
      font-size: 20px;
    }

    .detailsContainer{
      padding: 20px;
      font-size: 16px;
    }
  }
  
  @media (max-width: 991px) {
    .title {
      max-width: 100%;
    }
  
    .detailsContainer {
      max-width: 100%;
      padding:  20px;
    }

    .container{
      padding: 15px 15px 50px;
    }
  }

  @media(max-width: 900px){
    .title{
      font-size: 18px;
    }

    .detailsContainer{
      font-size: 14px;
    }
  }

  @media (max-width: 600px){
    .title{
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .container{
      padding: 0 0 24px;
    }

    .detailsContainer{
      padding: 10px;
    }

    .row{
      padding: 5px;
    }

    .detailsContainer{
      font-size: 10px;
      font-weight: 400;
      border-radius: 5px;
    }
  }