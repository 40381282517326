.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.type-list * {
  max-width: none !important;
}

.line-height-0 * {
  line-height: unset !important;
}

.max-width-25 {
  max-width: 25rem;
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  vertical-align: unset;
}

.filters-list {
  flex-direction: column !important;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.filters-list .ant-form-item {
  width: clamp(10rem, 100%, 100%);
  margin: 0 !important;
}

@media screen and (min-width: 768px) {
  .filters-list {
    gap: 1rem;
    flex-direction: row !important;
  }

  .filters-list .ant-form-item {
    width: clamp(10rem, 100%, 15rem);
  }
}

/*slider*/

.slick-dots li button:before {
  color: #7e7e7e !important;
  /* Color for non-selected dots */
  font-size: 15px !important;
}

.slick-dots li.slick-active button:before {
  color: #00c90a !important;
  /* Color for selected dot */
  font-size: 15px !important;
}

.slick-dots {
  margin-top: 50px !important;
  position: static !important;
}

.slick-dots {
  margin-top: 30px !important;
  position: static !important;
  border-top: 3px solid #f7f8ff;
  padding-top: 20px !important;
}

.video-outer .slick-dots {
  margin-top: 0px !important;
  position: static !important;
  border-top: 3px solid #f7f8ff;
  padding-top: 20px !important;
}

.slick-dots li {
  margin: 0 0 !important;
}

@media (max-width: 1200px) {
  .slick-dots {
    margin-top: 20px !important;
    position: static !important;
  }

  .video-outer .slick-dots {
    margin-top: 0;
  }

  .slick-dots li {
    margin: 0 0 !important;
  }

  .slick-dots li button:before {
    font-size: 12px !important;
  }

  .slick-dots li.slick-active button:before {
    font-size: 12px !important;
  }
}

@media (max-width: 970px) {
}

@media (max-width: 600px) {
  .slick-dots li button:before {
    font-size: 6px !important;
  }

  .slick-dots li.slick-active button:before {
    font-size: 6px !important;
  }

  .slick-dots li button {
    padding: 0 !important;
  }

  .slick-dots {
    margin-top: 10px !important;
    padding-top: 10px !important;
  }
}

.page-outer-div {
  padding-left: 25px;
  padding-right: 25px;
}

@media (max-width: 1250px) {
  .page-outer-div {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 480px) {
  .page-outer-div {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 970px) {
  .slick-dots li:nth-child(n + 6) {
    display: none !important;
    /* Hide dots from the sixth dot onward */
  }
}

.ant-table table {
  background: #fff !important;
}

.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  left: 50% !important;
  transform: translateX(-50%) !important;
  position: fixed;
  top: 20px;
  width: 400px;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
  left: 50% !important;
  position: fixed;
  transform: translateX(-50%) !important;
  top: 0px !important;
  width: 400px;
}

.absolute-button {
  position: absolute;
}

.ant-popover-message > .anticon {
  display: none !important;
}

.ant-popover-message-title {
  padding-left: 0 !important;
}

.common-select {
  width: 150px;
}

.ant-select-item,
.ant-select-item-empty {
  font-size: 16px !important;
}

@media (max-width: 600px) {
  .ant-select-item,
  .ant-select-item-empty {
    font-size: 12px !important;
  }
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
  display: flex !important;
  align-items: center !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
  margin-top: -6px !important;
}

.button-center {
  margin-left: auto;
  margin-right: auto;
}

.custom-row-height {
  height: 70px !important;
}

.invoice-table .ant-table-wrapper {
  margin-top: -20px !important;
}

.invoice-table {
  display: flex;
  gap: 80px !important;
}

.invoice-form {
  margin-top: 45px;
}

@media (max-width: 1600px) {
  .invoice-table {
    gap: 20px !important;
  }

  .invoice-table .ant-table-wrapper {
    margin-top: -42px !important;
  }

  .custom-row-height {
    height: 50px !important;
  }

  .invoice-table .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    position: relative;
    padding: 8px !important;
    overflow-wrap: break-word;
  }

  .invoice-form {
    margin-top: 25px;
  }
}

.title-container .ant-select-selection-placeholder {
  color: #474444 !important; /* Change to your desired color */
  font-weight: 500 !important;
}

.details-select
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #00c90a !important;
  color: white !important;
}

.details-select {
  width: 300px !important;
  font-size: 18px !important;
}

.notification-email{
  width: 504px !important;
}

.notification-email-minus{
  margin-left: -240px !important;
  margin-top: 40px !important;
}

@media(max-width: 1600px){
  .notification-email{
    width: 333px !important;
  }

  .notification-email-minus{
    margin-left: -125px !important;
    margin-top: 40px !important;
  }
}

